/* For screens up to 600px wide (typical mobile screens) */
@media (max-width: 600px) {
    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      /* min-height: 100vh; */
    }
  }


    
  /* For screens wider than 600px (typical desktop screens) */
  @media (min-width: 601px) {
    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      /* height: 100vh; */
    }
  
  }
  .icon .leaflet-div-icon{
        background: none;
    border: none;
  }